import React from "react"
import { Formik } from 'formik';
import request from 'request';




export default class Section9 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            sentStatus: 'initial',
        }
    }
    render(){
        let _this = this
        return (
             <div className="w-full px-0 py-20">
                 <div className="flex w-full">
                    <p className="text-5xl w-full text-center text-white font-bold text-aileron-bold">
                        Get started now!
                    </p>
                </div>
                <div className="lg:flex w-full">
                <div className="w-full lg:w-1/2 self-center p-10 lg:pr-10 py-20">
                    <p className="text-4xl text-white text-aileron-bold">
                        Contact Us
                    </p>
                    <div className="divider bg-accent my-8"></div>
                    <div>
                    <Formik
                        initialValues={{ 
                            firstname: '',
                            lastname: '',
                            company: '',
                            email: '',
                            phone: '',
                            message: '',
                        }}
                        validate={values => {
                            const errors = {};
                            if (!values.email) {
                                errors.email = 'Required';
                            } else if (
                                !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
                            ) {
                                errors.email = 'Invalid email address';
                            }
                            if (!values.phone) {
                                errors.phone = 'Required';
                            }
                            if (!values.message) {
                                errors.message = 'Required';
                            }
                            if (!values.firstname) {
                                errors.firstname = 'Required';
                            }
                            if (!values.lastname) {
                                errors.lastname = 'Required';
                            }
                            return errors;
                        }}
                        onSubmit={(values, { setSubmitting,resetForm }) => {
                            

                            var options = { method: 'POST',
                                url: 'https://7lqd9e8ppi.execute-api.ap-southeast-1.amazonaws.com/development/sendEmail',
                                headers: { 
                                    'Content-Type': 'application/json'
                                },
                                body: values,
                                json: true 
                                };

                                // _this.setState({sentStatus: 'success'})
                                // setTimeout(() => {
                                //             resetForm({ 
                                //             firstname: '',
                                //             lastname: '',
                                //             company: '',
                                //             email: '',
                                //             phone: '',
                                //             message: '',
                                //         });
                                //     _this.setState({sentStatus: 'initial'})
                                //     setSubmitting(false)
                                // }, 4000);
                                let tOut;
                                request(options, function (error, response, body) {
                                    if (error) {
                                        _this.setState({sentStatus: 'error'})
                                    }else{
                                        console.log(body);
                                        _this.setState({sentStatus: 'success'})
                                        resetForm({ 
                                            firstname: '',
                                            lastname: '',
                                            company: '',
                                            email: '',
                                            phone: '',
                                            message: '',
                                        });
                                    }
                                })
                                tOut = setTimeout(() => {
                                    _this.setState({sentStatus: 'initial'})
                                    setSubmitting(false)
                                    clearTimeout(tOut)
                                }, 4000);

                            
                        }}
                        >
                        {({
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            isSubmitting,
                            }) => {
                                let buttonUI = <button 
                                        type="submit" 
                                        disabled={isSubmitting}
                                        className="bg-accent-dark hover-bg-accent text-white font-bold py-3 px-6 rounded "
                                    >Submit</button>


                                if(this.state.sentStatus == 'success')
                                    buttonUI =<button 
                                            type="submit" 
                                            className="bg-green-500 text-white font-bold py-3 px-6 rounded "
                                        >Submitted!</button>

                                if(this.state.sentStatus == 'error')
                                    buttonUI =<button 
                                            type="submit" 
                                            className="bg-red-500 text-white font-bold py-3 px-6 rounded "
                                        >Error! Please try again later.</button>
                                        
                            
                            return (
                                <form  className="w-full" autoComplete="off" onSubmit={handleSubmit}>
                                    <div className="flex flex-wrap -mx-3 mb-6">
                                        <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                                            <label className="block uppercase tracking-wide text-white text-md font-bold mb-2" for="grid-first-name">
                                                First Name
                                            </label>
                                            <input 
                                                value={values.firstname} 
                                                onChange={handleChange} 
                                                name="firstname" 
                                                className="appearance-none block w-full bg-gray-200 text-grey-800 border border-grey-800 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white" id="grid-first-name" type="text" 
                                                placeholder="Juan"
                                            />
                                            <p className="text-red-500 text-xs italic  h-5 mt-3">
                                                { errors.firstname && touched.firstname && errors.firstname}
                                            </p>
                                        </div>
                                        <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                                            <label className="block uppercase tracking-wide text-white text-md font-bold mb-2" for="grid-first-name">
                                                Last Name
                                            </label>
                                            <input 
                                                value={values.lastname} 
                                                onChange={handleChange} 
                                                name="lastname" 
                                                className="appearance-none block w-full bg-gray-200 text-grey-800 border border-grey-800 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white" id="grid-first-name" type="text" 
                                                placeholder="Dela Cruz"
                                            />
                                            <p className="text-red-500 text-xs italic  h-5 mt-3">
                                                { errors.lastname && touched.lastname && errors.lastname}
                                            </p>
                                        </div>
                                        <div className="w-full md:w-full px-3 mb-6 md:mb-0">
                                            <label className="block uppercase tracking-wide text-white text-md font-bold mb-2" for="grid-first-name">
                                                Company
                                            </label>
                                            <input 
                                                value={values.company} 
                                                onChange={handleChange} 
                                                name="company" 
                                                className="appearance-none block w-full bg-gray-200 text-grey-800 border border-grey-800 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white" id="grid-first-name" type="text" 
                                                placeholder=""
                                            />
                                            <p className="text-red-500 text-xs italic  h-5 mt-3">
                                                {/* { errors.email && touched.email && errors.firstname} */}
                                            </p>
                                        </div>
                                        <div className="w-full md:w-full px-3 mb-6 md:mb-0">
                                            <label className="block uppercase tracking-wide text-white text-md font-bold mb-2" for="grid-first-name">
                                                Email
                                            </label>
                                            <input 
                                                value={values.email} 
                                                onChange={handleChange} 
                                                name="email" 
                                                className="appearance-none block w-full bg-gray-200 text-grey-800 border border-grey-800 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white" id="grid-first-name" type="text" 
                                                placeholder="Ex: john@email.com"
                                            />
                                            <p className="text-red-500 text-xs italic  h-5 mt-3">
                                                { errors.email && touched.email && errors.email}
                                            </p>
                                        </div>
                                        <div className="w-full md:w-full px-3 mb-6 md:mb-0">
                                            <label className="block uppercase tracking-wide text-white text-md font-bold mb-2" for="grid-first-name">
                                                Phone
                                            </label>
                                            <input 
                                                value={values.phone} 
                                                onChange={handleChange} 
                                                name="phone" 
                                                className="appearance-none block w-full bg-gray-200 text-grey-800 border border-grey-800 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white" id="grid-first-name" type="text" 
                                                placeholder="Ex: +63 917 *** ****"
                                            />
                                            <p className="text-red-500 text-xs italic  h-5 mt-3">
                                                { errors.phone && touched.phone && errors.phone}
                                            </p>
                                        </div>
                                        <div className="w-full md:w-full px-3 mb-6 md:mb-0">
                                            <label className="block uppercase tracking-wide text-white text-md font-bold mb-2" for="grid-first-name">
                                                Message
                                            </label>
                                            <textarea onChange={handleChange} name="message" className="appearance-none block w-full bg-gray-200 text-grey-800 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-message" > 
                                                {values.message}
                                            </textarea>
                                            <p className="text-red-500 text-xs italic  h-5 mt-3">
                                                { errors.message && touched.message && errors.message}
                                            </p>
                                        </div>
                                        <div className="w-full px-3 text-right">
                                            {buttonUI}
                                        </div>
                                    </div>
                                
                                </form>
                                )
                            }
                        }
                        </Formik>
                    </div>
                </div>
                <div className="w-full lg:w-1/2 self-top pl-10 py-20">
                    <div className="w-full mb-20">
                        <p className="text-4xl text-white text-aileron-bold">
                            Stay in Touch
                        </p>
                        <div className="divider bg-accent my-8"></div>
                        <div className="flex flex-wrap mb-6">
                        
                            <div className="w-full md:w-1/2 px-0 md:mb-0">
                                <div className="flex text-white mb-2">
                                    <div className="w-30 h-30" >
                                    <img 
                                        className=""
                                        src={require("../../../assets/svg/icn-email.svg")}
                                        alt=""
                                    /></div>
                                    <a href="mailto:hello@ciergio.com" target="_blank" className="pl-2"> <span className="font-bold">EMAIL</span> <br/> hello@ciergio.com </a>
                                </div>
                            </div>
                            <div className="w-full md:w-1/2 px-0 md:mb-0">
                                <div className="flex text-white mb-2">
                                    <div className="w-30 h-30" >
                                    <img 
                                        className=""
                                        src={require("../../../assets/svg/icn-address.svg")}
                                        alt=""
                                    /></div>
                                    <div className="pl-2"> <span className="font-bold">ADDRESS</span> <br/> 2/F 670 Stg. Bumatay St., <br/>Mandaluyong City, <br/>Philippines </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="w-full">
                        <p className="text-4xl text-white text-aileron-bold">
                            Social Media
                        </p>
                        <div className="divider bg-accent my-8"></div>
                        <div className="flex flex-wrap mb-6">
                        
                            <div className="w-full md:w-1/2 px-0 md:mb-0">
                                <div className="flex text-white mb-2">
                                    <div className="w-30 h-30">
                                    <img 
                                        className=""
                                        src={require("../../../assets/svg/icn-fb.svg")}
                                        alt=""
                                    />
                                    </div>
                                    <a href="https://www.facebook.com/Ciergio" target="_blank" className="pl-2"> <span className="font-bold">FACEBOOK</span> <br/> facebook.com/ciergio </a>
                                </div>
                            </div>
                           

                        </div>
                    </div>
                </div>
            </div>
            </div>
        )
    }
}